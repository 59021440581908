.snif-filter {
    position: relative;
    margin-right: auto;
    margin-left: 16px;
    /* Pushes this div to the left */
}

.snif-filter-header {
    display: flex;
    align-items: center;
}

.dropdown-icon-snifs {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 2px;
    margin-right: 4px;
    transition: transform 0.1s ease;
}

.dropdown-icon-snifs:hover {
    transform: scale(1.06);
}

.selected-snif {
    font-size: 16px;
    color: white;
    font-weight: bold;
}

.dropdown-menu-snifs {
    position: absolute;
    top: 100%;
    margin-top: 7px;
    left: 0;
    min-width: 140px;
    max-height: 290px;
    overflow-y: auto;
    background-color: rgb(24, 23, 23);
    color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 30;
}

.dropdown-item-snifs {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: rgb(40, 40, 40);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

/* Hover effect for unselected items */
.dropdown-item-snifs:hover {
    background-color: rgb(60, 60, 60);
}

/* Style for selected (checked) item */
.dropdown-item-snifs.checked {
    background-color: #797977;
    color: white;
    font-weight: bold;
}

/* Optional: Remove the bottom margin for the last item */
.dropdown-item-snifs:last-child {
    margin-bottom: 0;
}

/**/
/* MOBILE STYLINGS */
/**/

@media (max-width: 700px) {
    .snif-filter {
        margin-top: -47px;
        margin-left: 5px;
    }
}