.main-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min-content;
    z-index: 2;
    padding: 20px;
}

.carousel-movie-list-area {
    width: 100%;
}

@media (max-width: 700px) {
    .main-carousel {
        padding-left: 0px;
        padding-right: 0px;
    }
}