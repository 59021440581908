.coming-soon-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    padding-bottom: 0px !important;
}

.coming-soon-carousel {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    color: rgb(138, 132, 132);
    position: relative;
    border-left: 5px solid gray;
    border-right: 5px solid gray;
    border-radius: 25px;
    box-sizing: border-box;
    z-index: 10;
}

.coming-soon-carousel-wrapper {
    width: 1200px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.coming-soon-carousel-wrapper.open {
    max-height: 1000px;
    opacity: 1;
}

.coming-soon-carousel-inner {
    display: flex;
    flex-direction: row;
}

.coming-soon-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    scroll-snap-align: center;
    text-align: center;
}

.coming-soon-card>a>img {
    height: 180px;
    width: 120px;
}

.coming-soon-poster {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.coming-soon-details {
    font-size: 14px;
    padding: 5px;
}

.coming-soon-title {
    font-size: 1.2em;
    margin: 0;
    height: 40px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.coming-soon-details>p {
    margin: 0px;
}

.coming-soon-placeholder {
    width: 160px;
    height: 225px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    color: #666;
}

.coming-soon-chevron {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease-out;
    cursor: pointer;
    margin-bottom: -2.3px;
    margin-left: 2px;
}

@media (max-width: 700px) {
    .coming-soon-carousel-wrapper {
        width: 350px;
    }
}