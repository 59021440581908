.carousel-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 762px;
    height: 50px;
    background-color: rgb(42, 4, 112);
    border-radius: 25px;
    position: sticky;
    top: 90px;
    z-index: 30;
}

.clicking-controls {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    /* Start at the center of the parent */
    transform: translateX(-50%);
    /* Adjust to be horizontally centered */
}

.carousel-current-date {
    text-align: center;
    width: 100px;
}

.date-label {
    font-size: 1.2em;
    font-weight: bold;
}

.date-value {
    font-size: 1em;
    color: gray;
}

.previous-day-button {
    width: auto;
    min-width: 80px;
    border-radius: 15px;
    box-shadow: none;
    border-color: blueviolet;
}
.next-day-button {
    width: auto;
    min-width: 80px;
    border-radius: 15px;
    box-shadow: none;
    border-color: blueviolet;
}

.by-theater-dropdown-container {
    margin-left: auto;
    margin-right: 16px;
    position: relative;
}

.by-theater-dropdown-icon {
    cursor: pointer;
    width: 20px;
}

.by-theater-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgb(36, 35, 35);
    width: 160px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 10;
}

.by-theater-dropdown-menu>label>input {
    margin-right: 10px;
}

.left-chevron-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-right: 15px;
}
.right-chevron-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-left: 15px;
}

.chevron-container:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.chevron-container.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.chevron-icon {
    width: 16px;
    height: 16px;
}

@media (max-width: 700px) {
    .carousel-controls {
        width: 362px;
        height: 70px;
        top: 70px;
    }

    .clicking-controls {
        margin-bottom: -27px;
    }

    .by-theater-dropdown-container {
        margin-top: -47px;
        
    }
}