body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background: linear-gradient(#071f38, #031d4e, #01073f) no-repeat fixed; */
  background: rgb(2, 0, 36);
  background-size: cover;
  color: #c5c5c5;

  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.main-carousel-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.pre-carousel {
  width: 90%;
  gap: 5px;
  position: absolute;
  /* "absolute" to flow with the container above it I think */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1;
}

/* .home {
  width: 100%;
  height: 800px;
  align-items: center;
  justify-content: center;
  display: flex;
} */

/* .main-sections-drop {
  width: 90%;
  height: 90%;
  background-color: rgb(64, 77, 77);
  border-radius: 5px
} */

.testing-title-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: 'Tahoma';
  font-size: 45px;

  /* MAKE IT STAY AT TOP ALWAYS */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  background: rgb(145, 25, 113);
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid;
  /* MAKE IT STAY AT TOP ALWAYS */
}

.testing-title-holder>img {
  width: 210px;
  height: 70px;
  border-radius: 20px;
}

/**/
/* MOBILE STYLINGS */
/**/

@media (max-width: 700px) {
  .testing-title-holder>img {
    width: 150px;
    height: 50px;
    border-radius: 18px;
  }

  .main-carousel-holder {
    margin-top: 80px;
  }
}

/*make it not scrollable left/right*/
body {
  overflow-x: hidden;
}
/*make it not scrollable left/right*/